import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api';
import { cloneDeep, isEmpty } from 'lodash';
import VenueCateringForm from '@/pages/vendor/venue/catering/forms/VenueCateringForm';
import FormServiceItem from '@/shared/components/form/FormServiceItem.vue';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import PriceInput from '@/uikit/PriceInput.vue';
import FormSelect from '@/shared/components/form/FormSelect.vue';
import Popover from '@/components/Popover.vue';
import { VAT_RATES } from '@/shared/const';
import { debounce, servicesInOperationOrder } from '@/util/utils';
import getServices from '@/api/venue/getServices';
import toJSON from '@/api/base/toJSON';
import saveServices from '@/api/venue/patchServices';
import saveVenue from '@/api/venue/patch';
import addServices from '@/api/venue/postServices';
import ServiceModel from '@/shared/models/common/ServiceModel';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import Toggle, { Option } from '@/uikit/Toggle.vue';
import EventBus from '@/shared/services/eventBus';
export default defineComponent({
    components: {
        FormServiceItem,
        FormSelect,
        PriceInput,
        SaveFooter,
        Toggle,
        Option,
        Popover
    },
    props: {
        venue: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const isSaving = ref(false);
        const isInformingVenueUpdated = ref(false);
        const form = ref(new VenueCateringForm());
        const loadedForm = ref({});
        const errors = ref([]);
        const venueCatering = computed(() => globalThis.$store.getters['$_vendor/venueCatering']);
        const venueServices = computed(() => globalThis.$store.getters['$_vendor/venueServices']);
        const vatRates = computed(() => {
            return [
                {
                    label: root.$i18n.t('onboarding.organisation_details.select_option_vat_21'),
                    value: VAT_RATES.TWENTY_ONE
                },
                {
                    label: root.$i18n.t('onboarding.organisation_details.select_option_vat_9'),
                    value: VAT_RATES.NINE
                },
                {
                    label: root.$i18n.t('onboarding.organisation_details.select_option_vat_none'),
                    value: VAT_RATES.NONE
                }
            ];
        });
        const startInformingVenueUpdated = () => {
            isInformingVenueUpdated.value = true;
        };
        const cancelInformingVenueUpdated = () => {
            isInformingVenueUpdated.value = false;
        };
        watch(() => venueCatering.value, () => {
            if (!venueCatering.value.catering) {
                form.value.loadFromModel(venueCatering.value);
            }
            else {
                form.value.loadFromModel(venueServices.value.catering);
            }
        }, { immediate: true });
        const loadVenue = () => {
            const currentVenue = props.venue;
            if (currentVenue) {
                form.value.ownCatering = currentVenue.allowCatering;
            }
        };
        const updateCatering = services => {
            form.value.cateringItems = form.value.cateringItems.map(f => {
                const item = services.find(i => i.system_service_id === f.id);
                if (item) {
                    const model = new ServiceModel();
                    model.loadFromAPI(item);
                    model.selected = true;
                    return model;
                }
                return f;
            });
        };
        const loadCatering = async () => {
            const services = await toJSON(getServices(root.$route.params.venueId));
            updateCatering(services);
        };
        const isSettingsChanged = () => {
            if (!isEmpty(loadedForm.value)) {
                const _form = {
                    ...form.value,
                    cateringItems: form.value.cateringItems === undefined
                        ? undefined
                        : form.value.cateringItems.filter(item => item.selected)
                };
                const _loadedForm = {
                    ...loadedForm.value,
                    cateringItems: loadedForm.value.cateringItems === undefined
                        ? undefined
                        : loadedForm.value.cateringItems.filter(item => item.selected)
                };
                return JSON.stringify(_form) !== JSON.stringify(_loadedForm);
            }
            return false;
        };
        const hasChanges = () => {
            return !!root.$route.params.venueId && isSettingsChanged();
        };
        const isChangesSaved = () => {
            return !hasChanges() && isInformingVenueUpdated.value;
        };
        const onRevert = () => {
            form.value = cloneDeep(loadedForm.value);
        };
        const getVatError = (facility) => {
            if (errors.value.includes(`vatRate-${facility.id}`)) {
                return root.$i18n
                    .t('onboarding.organisation_details.errors.vat_rate_empty');
            }
        };
        const saveDataToLocalStorage = () => {
            if (!root.$route.params.venueId) {
                globalThis.$store.commit('$_vendor/SET_VENUE_SERVICES', {
                    model: 'catering',
                    data: { ...form.value }
                });
            }
            emit('settingsChanged', true);
        };
        const checkErrors = () => {
            errors.value = [];
            // Service VAT rate validation
            const selectedServices = form.value.cateringItems.filter(service => service.selected);
            selectedServices.forEach(el => {
                if (el.vat === null && el.price && el.price > 0) {
                    errors.value.push(`vatRate-${el.id}`);
                }
            });
            if (errors.value.length > 0) {
                root.$scrollTo(`#${errors.value[0]}`, 1500, { offset: -150 });
            }
            return errors.value;
        };
        const saveChanges = async () => {
            function generateServiceItems(arr) {
                return arr
                    .filter(s => s.selected)
                    .map(s => (s.price ? s : { ...s, price: 0 }))
                    .map(s => new ServiceModel(s).buildSendData());
            }
            const newCateringData = generateServiceItems(form.value.cateringItems);
            const oldCateringData = generateServiceItems(loadedForm.value.cateringItems);
            const { addedServices, changedServices, removedIds } = servicesInOperationOrder(oldCateringData, newCateringData);
            const requestBody = {
                venue_services: changedServices,
                venue_services_to_delete_ids: removedIds
            };
            await saveVenue(root.$route.params.venueId, {
                body: JSON.stringify({
                    allow_customer_catering: form.value.ownCatering
                })
            });
            await saveServices({ body: JSON.stringify(requestBody) });
            if (addedServices.length) {
                const requestBody = {
                    venue_id: root.$route.params.venueId,
                    venue_services: addedServices
                };
                const response = await addServices({
                    body: JSON.stringify(requestBody)
                });
                if (response.status === 201) {
                    const services = await response.json();
                    updateCatering(services);
                }
            }
        };
        const onSaveChanges = () => {
            checkErrors();
            if (errors.value.length > 0)
                return;
            saveChanges()
                .then(() => {
                loadedForm.value = cloneDeep(form.value);
                startInformingVenueUpdated();
            })
                .catch(cancelInformingVenueUpdated);
        };
        const mainAction = async () => {
            checkErrors();
            if (errors.value.length > 0)
                return;
            globalThis.$store.commit('$_vendor/SET_VENUE_SERVICES', {
                model: 'catering',
                data: { ...form.value, completed: true }
            });
            EventBus.$emit('updateWizardStep');
            globalThis.$router.push({
                name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.TAGS,
                params: { venueId: root.$route.params.venueId }
            });
        };
        watch(() => form.value, () => {
            const isServiceChecked = form.value.cateringItems.some(e => e.selected);
            if (isServiceChecked || !form.value.ownCatering)
                debounce(saveDataToLocalStorage(), 500);
        }, { deep: true, immediate: true });
        onMounted(() => {
            form.value.loadFromModel(venueServices.value.catering || venueCatering.value);
            if (root.$route.params.venueId) {
                loadVenue();
                loadCatering().then(() => {
                    loadedForm.value = cloneDeep(form.value);
                });
            }
        });
        return {
            errors,
            form,
            isInformingVenueUpdated,
            isSaving,
            loadedForm,
            vatRates,
            venueCatering,
            venueServices,
            cancelInformingVenueUpdated,
            checkErrors,
            getVatError,
            hasChanges,
            isChangesSaved,
            mainAction,
            onRevert,
            onSaveChanges,
            saveChanges,
            startInformingVenueUpdated
        };
    }
});
